@import "specs";

body {
    /* Sticky footer */
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    /* Padding correction for bibbase */
    padding-left:inherit;
    padding-right:inherit
}

.content {
    flex: 1;
}
.container.content {
    padding-left: $layout-spacing-lg;
    padding-right: $layout-spacing-lg;
}

code, kbd, pre, samp {
    font-family: 'Courier';
}

/*
    Navbar styles
*/
header.navbar {
    position: sticky;
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      position: -webkit-sticky;
    }
    top: 0;
    background-color: $dark-color;
    margin-bottom: 20px;
    z-index: 1000;

    a {
        font-size: $menu-font-size;

        i.icon {
            margin-top: -$layout-spacing-lg;
        }
    }

    a.top-menu-link {
        color: $mei-white;

        &:focus {
            background: $mei-highlight;
            color: $mei-white;
        }

        &:hover {
            background: $mei-highlight;
            color: $mei-white;
        }
    }

    a.menu-link {
        color: $mei-dark;
    }

    .menu {
        z-index: 1000;
    }

    .navbar-brand {
        margin: $layout-spacing-lg $layout-spacing-lg 0 $layout-spacing-lg;
        img {
            height: 40px;
        }
    }

    .logo {
        margin-right: 20px;
        height: 30px;
    }

	section div.guidelines-warning {
		width: 400px;
	}

  #socialLinks {
      flex-grow: 0;
      margin-right: -$layout-spacing-sm;
  }

  #menu-accordion {
    color: $mei-white;
    background-color: inherit;

    a {
      color: $mei-white;
    }

    .accordion-body {

      .menu {
        background-color: inherit;
      }

    }
  }
}

.newsMenu {

    .divider {
        margin: $layout-spacing-sm 0 $layout-spacing-lg;
    }
    
    p.tile-title {
        margin: .1rem 0 -.2rem;
    }

    h5.tile-title {
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-left: unset;
    }

}

.newsTitle {
    small {
        display: block;
        color: #666666;
        font-size: 70%;
    }
}

.newsEntry {
    margin-left: 1rem;
    margin-bottom: 3rem;
}

.startCarousel {
    margin: 0 0 30px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3);
    overflow: visible;
    z-index:1;

    .carousel-container {
      border-radius: 5px;
      overflow: hidden;
    }

  .carousel-container::before {
      padding-bottom: 33.25%;
  }

  .carousel-nav {
    bottom: -2rem;
  }
}

.columns.column {
  margin-left: 0;
  margin-right: 0;
}

footer {
    margin-top: 20px;
    background-color: $dark-color;
    color: $mei-white;
    padding-bottom: 30px;

    a {
        color: $mei-white;
        font-weight: 300;
        &:visited, &:hover, &:active {
            color: $mei-white;
        }
    }

    .institutions {
        padding: 20px;
        & a.institutionsLink {
           img {
               height: 80px;
           }
        }
    }
}

p.sectionToc a {
    display: block;
    margin-top: .4rem;
    margin-left: 1rem;
    text-indent: -1rem;

    &.level-3 {
        margin-left: 2.5em;

    }

    &.level-4 {
        margin-left: 4em;
    }
}

p.mainToc {
	margin: 0 0 0.2rem;
}

a.link_odd_elementSpec {
    &:before {
        content: '<';
    }
    &:after {
        content: '>';
    }
}

a.guidelines-edit {
    margin-left: 10px;
    float: right;
    opacity: .2;
}

p.gloss {
	padding: .2rem .4rem;
	background: #ebebeb;
}

p.gloss {
	margin-top: -10px;
}

h2:hover a.guidelines-edit, h3:hover a.guidelines-edit, h4:hover a.guidelines-edit, h5:hover a.guidelines-edit, h6:hover a.guidelines-edit {
    opacity: 1;
}

.wovenodd-col1 {
    vertical-align: top;
}

.wovenodd-col2 {

    .table td, .table th {
        border-bottom: none;
    }

    .table.table-striped tbody tr:nth-of-type(2n+1) {
        background: #f5f5f5;
    }

    .schematronText {
        margin-bottom: .8rem;
    }

    img {
        max-width: 100%;
    }

    .code {

        code {
            background-color: #f5f5f5;
            border: .5px solid #cccccc;
        }

        .indent1 .indent {
            margin-left: 1em;
        }

        .element {
            color: #000080;
            color: #4767b1;

            .attribute {
                color: #008080;
                color: #f98859;

            }

            .attributevalue {
                color: #dd1144;
                color: #a14415;
            }
        }

        .comment {
            color: #999988;
            color: #208420;
            font-style: italic;
        }

        /*.attribute .attributevalue {
            margin-left: -.6em;

            a {
                margin-left: -.6em;
            }
        }*/
    }

}

div.tile {

    margin-left: 1rem;

    & + div.tile {
        margin-top: .5rem;
    }

    & + p, + h1, + h2, + h3, + h4, + h5, + h6 {
        margin-top: 1em;
    }

}

// * + h3 {
//     margin-top: 4rem;
// }

// * + h4 {
//     margin-top: 2rem;
// }

// * + h5, * + h6 {
//     margin-top: 2rem;
// }

// h1, h2, h3, h4, h5, h6 {
//     margin-left: -1rem;
// }

ul.nav li.nav-item:first-child {
    margin-top: 0;

    a {
        padding-top: 0;
    }
}

.projects, .conferences {
    .card {
        margin-bottom: 1rem;
        box-shadow: 0 5px 15px #00000033;
        height: calc(100% - 1rem);
        justify-content: space-between;

        .card-image {
            overflow: hidden;
        }

        img.mei-project-image {
            width:100%;
            height: 150px;
        }
    }
}

.arrow-tile-icon {
    align-content: space-around;
    align-items: center;
    background: #5755d9;
    border-radius: .1rem;
    color: #fff;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: center;
    -ms-flex-line-pack: distribute;
    font-size: 1.2rem;
    height: 2rem;
    width: 2rem;
}


.icon {
    color: #ffffff;
}

/* Tutorials Start */

.tutorialBox {

    h3 {
        font-size: 1rem;
        margin: .8rem 0 0;
    }

    .tutorialGoal{
        margin-top: .5rem;
    }
    .tutorialGoal > span, .tutorialTask > span {
        font-weight: bold;
    }

    
    #stepBox {
        margin: 2rem 0;
    }

    #btn-openFullFileModal {
        font-size: .65rem;
        font-weight: 400;
        cursor: pointer;
    }

    #btn-toggleHint {
      font-size: .65rem;
      font-weight: 400;
      cursor: pointer;
    }

    #btn-toggleHint:focus {
      box-shadow: none;
    }

    #editorBox {
        height: 5rem;
        margin: .5rem 0;

        background-color: #e5e5e5;
        border: .5px solid #999999;
        border-radius: .3rem;
        padding: .3rem;

        #editor {
            background-color: transparent;
            font-size: .9rem;
            font-family: 'Courier';
            height: 100%;

            * {
                font-family: 'Courier';
            }
        }

    }
    #fullFileModal {

        a#fullFileModalCloseBack {
            background-color: #333333;
            opacity: .9;
        }

        .modal-body {

            height: 20rem;

            #fullFileContent {
                background-color: transparent;
                font-size: .7rem;
                font-family: 'Courier';
                height: 100%;

                * {
                    font-family: 'Courier';
                }
            }
        }
    }

    #acknowledgments {
        font-size: .7rem;
        margin: 3rem 0 0;

        h3 {
            font-size: .8rem;
        }

        p {
          margin-bottom: .2rem;
        }

        #ackList {
            font-size: .7rem;
            list-style-type: none;
            margin: .5rem 0;

            .name {
                font-weight: 500;
            }

            .affiliation {
                font-weight: 300;
                color: #666666;

                &:before {
                    content: ' | ';
                }
            }
        }


    }

    pre.codeblock {
        background-color: #f5f5f5;
        border: .5px solid #999999;
        border-radius: .5rem;
        padding: .5rem 1rem;
        font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", Menlo, Courier, monospace;
        font-size: 0.7rem;
        overflow: auto;

        & + label {
            font-size: .7rem;
            font-weight: 100;
            position: relative;
            top: -.9rem;
            left: .5rem;
        }
    }

    #rendering {
        min-height: 4rem;
        margin: 0 .5rem .5rem;

    }

    #nextStepButton {
        margin: .5rem 0 2.5rem;
    }

    #instruction h1 {
        font-size: 1rem;
        margin: .8rem 0 .2rem;
    }

    #instruction h2 {
        font-size: .9rem;
        margin: .8rem 0 .1rem;
    }

    #instruction h3 {
        font-size: .8rem;
        margin: .8rem 0 0;
    }
}

/* Tutorials End */

/* Code Display for Samples */

.sampleCode pre {
    overflow: auto;
    background-color: #f5f5f5;
    padding: .5rem;
    border-radius: .25rem;

    code {
        background: unset;
    }
}

/* Tag Library */

.attributeDef  {

    .attributeUsage, .attributeDesc {
        margin-left: .3rem;
    }

    .attributeClasses {

        font-weight: 300;

        &:before {
            content: '(→ ';
            margin-left: 5px;
        }

        &:after {
            content: ')';
        }
    }

}


table.wovenodd {

    border-collapse: collapse;
    width: 100%;

    & > tbody > tr > td {
        border: .5px solid #cccccc;
        padding: .3rem;

        table thead {
            display: none;
        }
    }
}

/* oldGuidelines fixes */

.oldGuidelines {

	.panel {
		border: none;
	}

	table.wovenodd {
		border: none;

		td {
			.label {
				border-radius: 0;
				padding: 0;
				background: none;
				color: unset;
				display: inline;
				font-weight: bolder;
			}
		}

		.wovenodd-col2 .attributeDef {
			padding: .6rem .4rem;

			.attribute {
				font-weight: bolder;
			}
		}

		.wovenodd-col2 .attributeDef:nth-of-type(2n+1) {
    		background: #f5f5f5;
		}

		.wovenodd-col2 .specChildren .specChild {
			margin: 0 0 .4rem 2rem;
			text-indent: -2rem;

			.specChildModule {
				font-weight: bolder;
			}
		}

	}

	.pre {

    	background-color: #f5f5f5;
        border: .5px solid #cccccc;
        font-family: "SF Mono","Segoe UI Mono","Roboto Mono",Menlo,Courier,monospace;
        border-radius: .1rem;
        font-size: 85%;
        color: inherit;
		display: block;
		line-height: 1.5;
		overflow-x: auto;
		padding: 1rem;
		width: 100%;

        .indent2, .indent3, .indent4, .indent5, .indent6, .indent7, .indent8 {
        	margin-left: 1em;
 	    }

        .element {
	        color: #000080;
    	    color: #4767b1;

            .attribute {
                color: #008080;
                color: #f98859;

            }

            .attributevalue {
                color: #dd1144;
                color: #a14415;

                margin-left: -.4rem;

                .link_odd {
                	margin-left: -.4rem;
                }
            }
        }

        .comment {
            color: #999988;
            color: #208420;
            font-style: italic;
        }
    }

	.textwidget {

		.pre {
			margin-bottom: 1.2rem;
		}

		.att:before {
			content: '@'
		}

		ul.specList {
			list-style-type: none;
			margin-left: 2rem;

			.specList-elementSpec {
				font-weight: 700;
				margin-right: .4rem;
			}
		}

		dl {
			width: 100%;
			overflow: hidden;

			dt {
				float: left;
				width: 30%;
				box-sizing: border-box;
				padding: .6rem .4rem;
			}

			dd {
				float: left;
				width: 70%;
				box-sizing: border-box;
				padding: .6rem .4rem;
				margin: 0;

				&:after {
					content:'none';
					color: transparent;
				}
			}

			dt:nth-of-type(2n+1), dd:nth-of-type(2n+1) {
				background: #dbdbdb;
			}
		}

		.specDesc .att {
    		margin-right: 10px;
		}

		table.specDesc td.Attribute {
    		font-weight: 700;
    		vertical-align: top;
		}
	}

	.sortedInitials.well {
    	border: .5px solid #ccc;
    	border-radius: 5px;
    	background-color: #f5f5f5;
    	margin: 0 10px 30px;
    	padding: 0 10px 10px;

    	a {
    		text-decoration: none;
    		margin-right: 1rem;
    		display: inline-block;

    	}
	}

	/* right column */
	.panel-grid-cell + .panel-grid-cell {
		padding-left: 1rem;
	}

	.guidelinesList {
		list-style-type: none;
	}
}


/**
 * SMuFL
 */
.smufl {
    width: 1.5em;
    vertical-align: bottom;
}

/**
 * specs
 */
 .specsLayout {

 }
