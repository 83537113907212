@charset "utf-8";

$baseurl: "";

$mei-dark: #333333;
$mei-highlight: #2440AA;
$mei-white: #FFFFFF;
$mei-light: #444140;

$primary-color: $mei-highlight;
$dark-color: $mei-dark;


// Our variables
// $base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   0.8rem;
$small-font-size:  $base-font-size * 0.875;
$menu-font-size:   0.8rem;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "spectre/spectre.scss",
        "spectre/spectre-exp.scss",
        "spectre/spectre-icons.scss",
        "mei.scss",
        "bib.scss"
;

/* MEC 2020 */
.mec2020-register {
  font-weight: bold;
  border: 1px solid $mei-highlight;
}

.mec2020-register:hover {
  border: none;
  border: 1px solid white;
}

.mec2020-page {
  h2, h3, h4, h5 {
    margin: .5em 0 .5em 0;
  }

  .mec2020-logo {
    height: 6em;
  }

  .mec2020-program-row-main {
    margin: 30px 0 30px 15px;

    .mec2020-program-title {
      margin: 10px 0 10px 0;

      .mec2020-presentation {
      background-color:#d4d4d4;
      }
    }

    .workshop {
        width: 185pt;
        height: 190pt;
    }

    .workshop-title {
        width: 160pt;
        height: 105pt;
    }

    .poster-session {
        width: 370pt;
        height: 520pt;
    }

    .mec2020-program-speakers {
      font-style: italic;
      font-size: 0.7rem;
    }

    .unconference{
        height: 95pt;
    }

    .small-card {
      width: 250px;
    }

    .card {
      margin: 20px 0 20px 0;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

      .card-title {
        font-weight: bold;
      }

    }

  }

  .mec2020-abstract {
        font-size: 20px;
        font-weight: bold;

  }
  .mec2020-program-row-main {
    margin: 30px 0 30px 0;
    background-color: #f4f4f4;
    padding: 20px 10px 20px 10px;
  }
}


/* MEC 2021 */
/* TODO: merge into single conference layout? */
#mec21programTable h5 {
    font-size: 0.7rem;
    font-weight: bold;
}

.mec2021-page {

    margin-top: 2em;

    h2,
    h3,
    h4,
    h5 {
        margin: 1em 0 .5em 0;
    }

    .mec2021-logo {
        height: 6em;
    }

    .mec2021-program-row-main {
        margin: 30px 0 30px 15px;

        .mec2021-program-title {
            margin: 10px 0 10px 0;

            .mec2021-presentation {
                background-color: #d4d4d4;
            }
        }

        .workshop {
            width: 185pt;
            height: 190pt;
        }

        .workshop-title {
            width: 160pt;
            height: 105pt;
        }

        .poster-session {
            width: 370pt;
            height: 520pt;
        }

        .mec2021-program-speakers {
            font-style: italic;
            font-size: 0.7rem;
        }

        .unconference {
            height: 95pt;
        }

        .small-card {
            width: 250px;
        }

        .card {
            margin: 20px 0 20px 0;
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

            .card-title {
                font-weight: bold;
            }

        }
    }

    /* 20210409 */
    table {
        font-family: "Arial", sans-serif;
        font-size: 0.9em;
        border-collapse: collapse;
        width: 100%;
    }

    td {
        border: 1px solid #999999;
        padding: 8px;
        text-align: center;
    }

    th {
        background-color: #8998e0;
        color: white;
        border: 1px solid white;
        padding: 8px;
        text-align: center;
    }

    small {
        font-size: 0.9em;
    }

    button {
        border: none;
        border-radius: 8px;
        color: white;
        padding: 10px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        background-color: #1a235b;
    }

    .keynotesTable td {
        text-align: left;
        vertical-align: top;
        border: none;
    }

    .keynotesTable img {
        width: 230px;
        height: auto;
    }

    .keynotesTable h5 {
        margin-top: 0;
    }

}

.filter-body {
    width: 100%;
}