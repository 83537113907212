// Parallax
$parallax-deg: 3deg !default;
$parallax-offset: 4.5px !default;
$parallax-offset-z: 50px !default;
$parallax-perspective: 1000px !default;
$parallax-scale: .95 !default;

// Mixin: Parallax direction
@mixin parallax-dir() {
  height: 50%;
  position: absolute;
  width: 50%;
  z-index: $zindex-1;
}

.parallax {
  display: block;
  height: auto;
  position: relative;
  width: auto;

  .parallax-content {
    @include shadow-variant(1rem);
    height: auto;
    transform: perspective($parallax-perspective);
    transform-style: preserve-3d;
    transition: all .4s ease;
    width: 100%;

    &::before {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .parallax-front {
    align-items: center;
    color: $light-color;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 20px rgba($dark-color, .75);
    top: 0;
    transform: translateZ($parallax-offset-z) scale($parallax-scale);
    transition: all .4s ease;
    width: 100%;
    z-index: $zindex-0;
  }

  .parallax-top-left {
    @include parallax-dir();
    left: 0;
    top: 0;

    &:hover ~ .parallax-content {
      transform: perspective($parallax-perspective) rotateX($parallax-deg) rotateY(-$parallax-deg);

      &::before {
        background: linear-gradient(135deg, rgba(255, 255, 255, .35) 0%, transparent 50%);
      }

      .parallax-front {
        transform: translate3d($parallax-offset, $parallax-offset, $parallax-offset-z) scale($parallax-scale);
      }
    }
  }

  .parallax-top-right {
    @include parallax-dir();
    right: 0;
    top: 0;

    &:hover ~ .parallax-content {
      transform: perspective($parallax-perspective) rotateX($parallax-deg) rotateY($parallax-deg);

      &::before {
        background: linear-gradient(-135deg, rgba(255, 255, 255, .35) 0%, transparent 50%);
      }

      .parallax-front {
        transform: translate3d(-$parallax-offset, $parallax-offset, $parallax-offset-z) scale($parallax-scale);
      }
    }
  }

  .parallax-bottom-left {
    @include parallax-dir();
    bottom: 0;
    left: 0;

    &:hover ~ .parallax-content {
      transform: perspective($parallax-perspective) rotateX(-$parallax-deg) rotateY(-$parallax-deg);

      &::before {
        background: linear-gradient(45deg, rgba(255, 255, 255, .35) 0%, transparent 50%);
      }

      .parallax-front {
        transform: translate3d($parallax-offset, -$parallax-offset, $parallax-offset-z) scale($parallax-scale);
      }
    }
  }

  .parallax-bottom-right {
    @include parallax-dir();
    bottom: 0;
    right: 0;

    &:hover ~ .parallax-content {
      transform: perspective($parallax-perspective) rotateX(-$parallax-deg) rotateY($parallax-deg);

      &::before {
        background: linear-gradient(-45deg, rgba(255, 255, 255, .35) 0%, transparent 50%);
      }

      .parallax-front {
        transform: translate3d(-$parallax-offset, -$parallax-offset, $parallax-offset-z) scale($parallax-scale);
      }
    }
  }
}
