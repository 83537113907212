// Off canvas menus
.off-canvas {
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;

  .off-canvas-sidebar {
    background: $bg-color;
    height: 100%;
    left: 0;
    padding: $layout-spacing-lg;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform .25s ease;
    width: 10rem;
    z-index: $zindex-3;
  }

  .off-canvas-content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: $layout-spacing-lg $layout-spacing-lg $layout-spacing-lg 4rem;
  }

  .off-canvas-checkbox {
    &:checked ~ .off-canvas-sidebar {
      transform: translateX(0);
    }

    &:checked ~ .off-canvas-toggle {
      background: rgba($dark-color, .1);
      border-color: transparent;
      border-radius: 0;
      bottom: 0;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;

      .icon {
        display: none;
      }
    }
  }

  .off-canvas-toggle {
    display: block;
    left: $layout-spacing-lg;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: $layout-spacing-lg;
    transition: none;
    width: $control-size-lg;
    z-index: $zindex-2;
  }
}

// Responsive layout
@media (min-width: $size-lg) {
  .off-canvas {
    .off-canvas-sidebar {
      flex: 0 0 auto;
      position: static;
      transform: translateX(0);
    }

    .off-canvas-content {
      padding-left: $layout-spacing-lg;
    }

    .off-canvas-toggle {
      display: none;
    }
  }
}
